import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp,FiCpu,FiGlobe,FiLock ,FiShoppingCart,FiCode,FiAtSign,FiAirplay,FiTablet} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const ServiceList = [
    {
        icon: <FiCpu />,
        title: 'IOT Development',
        description: 'We can help you unlock the potential of the Internet of Things (IoT) to improve your business insight, improve agility, maximize value chains and reduce costs.'
    },
    {
        icon: <FiLayers />,
        title: 'UI/UX approch',
        description: 'Our skills in user experience and user interface Designers can transform any design ideas into reality by creating for you an customized digital product.'
    },
    {
        icon: <FiUsers />,
        title: 'SaaS Development',
        description: 'Using SaaS strategies (Software as a Services) we are able to provide, secure and mentain you any kind of high quality digital products.'
    },
    {
        icon: <FiMonitor />,
        title: 'Desktop development',
        description: 'Desktop development implies the creation of software applications that perform on computers. We can create desktop application for Linux distro.'
            },
    {
        icon: <FiGlobe />,
        title: 'Web based Development',
        description: 'A web application (web app) does not need to be downloaded and is instead accessed through a network. An end user can access a web application through a web browser such as Google Chrome, Safari, or Mozilla Firefox.'
    },
    {
        icon: <FiLock />,
        title: 'Secure development',
        description: 'We treat security as priority for every step: design, architecture, development and operations. Our hosting data center is fast, secure, and backups your data 24/7.'
    },
    
]
const ServiceList2 = [
    {
        icon: <FiAtSign />,
        title2: 'Store design',
        description2: 'Select the best technologies for your e-commerce store, whether you’re a CTO well-versed in the latest technology choices, or a novice to the tech scene, from backend to frontend development.'
    },
    {
        icon: <FiCode/>,
        title2: 'Theme build',
        description2: 'We designs or redesign webstore that increases brand likeability on the markets. We develop your store from scrach or in a Content Management System, like Prestashop or woocomerce.'
    },
    {
        icon: <FiShoppingCart />,
        title2: 'API Development',
        description2: 'Integrating APIs for better services such as payment gateways and different shipping methods, and olso multi language and currency sistem.'
    },
    
]
const ServiceList3 = [
    {
        icon: <FiTablet />,
        title3: 'React Native App',
        description3: 'We are able to build and launch mobile independent apps by using amusing features of React Native platform to Android and iOS platforms without carrying the "heavy baggage" of native app development protocols.'
    },
    {
        icon: <FiAirplay />,
        title3: 'FLUTTER APP ',
        description3: 'Flutter enables ambient computing to make apps available on any device of choice whether it’s mobile, laptop, desktop, wearables, TV’s or smart displays. Avoid wasting your valuable time in deciding which devices to target.'
    },
    
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Services' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Services'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Custom development</h2>
                                    <p>Technology is the key to building companies that are efficient, productive, and profitable in modern markets. Having the right software enables staff members to do the work of multiple people, making it possible for your company to achieve the goals of a much larger company at a fraction of the cost. However, having the right software is essential for creating a market advantage. We have the skills and the resources to build custom software that makes it easier for your business to thrive in competitive markets.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="#">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Ecommerce solutions</h2>
                                    <p>Our expertise in custom eCommerce development services delivers efficent and secure platforms. We aim to unlock the potential of eCommerce platforms by creating a distinguished eCommerce business wich provides exlusive and reliable websites. Our team is passioned to creates customer-focused digital products using Laravel, Node js, React or CMS as Wordpress or Prestashop.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList2.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                   
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title2}</h3>
                                                <p>{val.description2}</p>
                                            </div>
                                        </div>
                                
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Mobile App builders</h2>
                                    <p>Mobile applications are the keystone for many businesses in our digital age. Running apps on smart devices grant customers greater access to company services. Smartphones have become very common terminal among all industries and markets. It a fact, technologies makes life much more easier and pleasant. When it comes to mobile app development, it is essential to understand what you expect from the software. The best mobile app development solution will allow your business to expand its presence in the market and get ahead of the competition.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList3.map( (val , i) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title3}</h3>
                                                <p>{val.description3}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;