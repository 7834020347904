import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";

class MainDemo extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <Fragment> 
                <Helmet pageTitle="ROA SOFTWARE " />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area   */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top ptb--60" >
                    <About />
                </div>
                {/* End About Area */}
         {/* Start Brand Area */}
         <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Tehnology we use</h3>
                                </div>
                            </div>
         <div className="rn-brand-area brand-separation ptb--60" >
                    <div className="brand-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <BrandTwo />
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                {/* End Brand Area */}
                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--4"style={{ backgroundImage: `url("/assets/images/bg/bg-image-12.jpg")` }}>
                    <div className="portfolio-sacousel-inner mb--55"style={{ backgroundImage: `url("/assets/images/bg/bg-image-12.jpg")` }}>
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}
                  {/* Start CounterUp Area */}
                  <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Some Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}
                {/* Start Testimonial Area */}
                <div className="section-title text-center">
                                    <h3 className="fontWeight500">What clients say</h3>
                                </div>
                <div className="rn-testimonial-area bg_color--1 ptb--80">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default MainDemo;