import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'Who we are',
        description = 'We are a software development company with over 10 years of experience in implementing complex web projects. We build, launch and manage web and mobile applications using technologies such as GraphQL, React Native, Typescript, but also the Laravel framework. Hence, our user-centric IT solutions are fast, scalable, robust and secure. More precisely, we can work for you in projects such as: online stores, complex web platforms, ERP/CRM soft, or presentation websites. We believe in long-term partnerships with our clients and we take great pride in our ability to keep our clients satisfied.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                    <div className="col-lg-12" style={{margin: "auto" }}>
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title"style={{textAlign:"center" }}>{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;