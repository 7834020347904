import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                        <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>We had a very good communication with Roasoftware, they were transparent and understood our needs very accurately. We will work with them în the future.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Stefan G.</span></h6>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>We were impressed by the level of professionalism that Roasoftware showed throughout our collaboration. I recommend this company</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Marius A. </span> </h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Working with Roasoftware helped us transform the way that we run our business in digital. We are very happy with this collaboration</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Ana M.</span></h6>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Excellent collaboration with Roasoftware, the website came out the way we wanted. Congratulations guys and keep up the good job!</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Razvan B.</span> </h6>
                            </div>
                        </div>
                    </TabPanel>
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                               
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;