import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    
        {
            image: 'image-1',
            title: 'Romanian fashion hub application',
            id:'1',
            visit:'https://saash.ro'
        },
        {
            image: 'image-2',
            title: 'Personal website for international visual artist',
            visit:'https://stefangrigoras.com'
        },
        {
            image: 'image-3',
            title: 'International booking application',
            visit:'https://travlocals.com'
        },
        {
            image: 'image-4',
            title: 'International online fashion store',
            visit:'https://maestoso-design.com'
        },
        {
            image: 'image-5',
            title: 'International marketplace for profesional hairstyles',
            visit:'https://honmatokyo.ro'
        },
        {
            image: 'image-6',
            title: 'Public institution portal',
            visit:'https://primariadoljesti.ro'
        },
    
    ]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , visit, index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href="/portfolio-details">{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.visit} target='{_blank}'>Visit platform</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to="/portfolio-details"></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;