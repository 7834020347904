import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-12"style={{ backgroundImage: `url("/assets/images/bg/bg-image-12.jpg")` }}>
                           
                                <div className="footer-right" data-black-overlay="6">
                                <div class="container">
                                    <div className="row">

                                        {/* Start Single Widget  */}
                                        <div className="col-lg-12 col-sm-12 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:contact@roasoftware.com">email: contact@roasoftware.com</a></li>
                                                    <li><a href="tel:+40771658083">phone: +40771658083</a></li>
                                                    <li><a href="https://roasotware.com/termeni.pdf">Terms & Conditions</a></li>
                                                    <li><a href="https://www.facebook.com/roasoftware/">Facebook account</a></li>
                                                    <li><a href="https://www.linkedin.com/company/roasoftware">Linkdin account</a></li>
                                                </ul>
                                                <p></p>
                                                <p></p>
                                                <p></p>
 <li>Copyright © 2022 SC TAKTICA WEB SRL. All Rights Reserved.</li>
                                                
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        </div>
                                       
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;