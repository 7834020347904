import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Custom Development',
        description: 'Custom development means you are getting exactly what you want: a custom web solution that perfectly matches your business. No more extra modules that you do not use or need, no more updates, no more bugs.'
    },
    {
        icon: '02',
        title: 'Mobile app builders',
        description: 'With our custom mobile application development services, we facilitate your business continuity plans and help extend your activities outside the office, and keep you close to your staff and customers.'
    },
    {
        icon: '03',
        title: 'eCommerce solutions',
        description: 'When it comes to online sales, our e-commerce solutions B2C/B2B is perfect for every type of business. We build modern, scalable e-commerce web/mobile applications that are custom tailored to your needs and growth goals.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1"> 
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                        
                    ))}
                </div>
            </React.Fragment>
            
        )
    }
}
export default ServiceOne;