import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import TextLoop from "react-text-loop";
const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Welcome to my World',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
class SliderOne extends Component {
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1 justify-content-center" data-black-overlay="6" >
            <div className="container">
                
                    <div className="row">
                    <div className="col-lg-8" style={{margin: "auto" }}>
                        <br></br>
                        <div className="desktop">
                                            <h2  style={{margin: "font-weight: 300 !important;" }}>
                                            <TextLoop>
                                                <span style={{color: "#a4c854" }}> Custom Web developers</span>
                                                <span style={{color: "#a4c854"}}>  Mobile applications builders </span>
                                                <span style={{color: "#a4c854"}}>  Online webstore creators</span>
                                            </TextLoop>{" "}
                                            </h2>
                                            </div>                       
                    </div>
                    </div>
                    {/* Start Service Area */}
                    <div className="service-wrapper service-white">
                        <ServiceOne />
                    </div>
                    
                    {/* End Service Area */}
                    <div className="row">
                                    <div className="col-lg-8" style={{margin: "auto" }}>
                                         <p style={{textAlign:"center"}}><img src="/assets/images/logo/scale.png" alt="Digital Agency" /></p>
                                            <p style={{color: "#fff",textAlign:"center"}}>scroll to explore</p>  
                                    </div> 
                                                   
                    </div>
                </div>
               
            </div> 
            
                    
                                                   
                                                   
            {/* End Single Slide */}
            
        </div>
    );
  }
  
}
export default SliderOne;