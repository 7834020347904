import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class About extends Component{
    render(){
        let title = 'About us',
        description = 'We are a software development company with over 10 years of experience in implementing complex web projects. We build, launch and manage web and mobile applications using technologies such as GraphQL, React Native, Typescript, but also the Laravel framework. Hence, our user-centric IT solutions are fast, scalable, robust and secure. More precisely, we can work for you in projects such as: online stores, complex web platforms, ERP/CRM soft, or presentation websites. We believe in long-term partnerships with our clients and we take great pride in our ability to keep our clients satisfied.';
     
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--30 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                
                {/* Start About Area  */}
                <div className="rn-about-area ptb--30 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">In code we trust</h2>
                                            <p className="description">We are a team that creates the future along  with colleagues, clients and partners. We strongly believe that communication and accurate information are very important. 
Our development process is very simple and well defined - Backlog Refinements, Sprint Review, Planning, regular meetings. You will have full access to GIT Repos in order to measure our daily progress.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                 {/* Start About Area  */}
                 <div className="rn-about-area ptb--30 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Tehnology we use</h2>
                                            <div className="brand-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <BrandTwo />
                                </div>
                            </div>
                        </div>
                    </div> 
                                             </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
 {/* Start Brand Area */}
 
 <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500"></h3>
                                </div>
                            </div>
         <div className="rn-brand-area brand-separation ptb--60" >
                  
                </div>
                {/* End Brand Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About