import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Slider from "react-slick";
import PortfolioList from "../elements/portfolio/PortfolioList";
import { slickDot , portfolioSlick2 } from "../page-demo/script";

const list = [
    {
        image: 'image-1',
       
        title: 'Romanian fashion hub application'
    },
    {
        image: 'image-2',
   
        title: 'Personal website for international visual artist'
    },
    {
        image: 'image-3',
       
        title: 'International booking application'
    },
    {
        image: 'image-4',
        
        title: 'International online fashion store'
    },
    {
        image: 'image-5',
      
        title: 'International marketplace for profesional hairstyles'
    },
    {
        image: 'image-6',
       
        title: ' Getting tickets to the big show'
    },
]


const PortfolioList2 = [
    {
        image: 'image-1',
      
        title: 'Romanian fashion hub application'
    },
    {
        image: 'image-2',
       
        title: 'Personal website for international visual artist'
    },
    {
        image: 'image-3',
       
        title: 'International booking application'
    },
    {
        image: 'image-4',
        
        title: 'International online fashion store'
    },
    {
        image: 'image-5',
        
        title: 'International marketplace for profesional hairstyles'
    },
    {
        image: 'image-6',
    
        title: ' Getting tickets to the big show'
    },

]

const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle='Counters' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Portfolio'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">Some of our projects</h2>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="9" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Portfolio;